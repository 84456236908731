.training-banner {
  height: auto;
  padding: 12px;
  /* background-size: cover; */
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #f5f8ff;
  margin-top: 60px;
}

.training-banner div {
  max-width: 1350px;
}

.training-info h1 {
  font-size: 36px;
  color: white;
}

.training-info p {
  color: #e8e8e8;
  font-size: 14px;
}

.training-form-div {
  padding: 26px 10px;
}

.training-form-box {
  border-radius: 8px;
  background-color: rgb(255, 255, 255, 0.9);
  box-shadow: 0px 0px 10px #54547b;
  padding: 30px 15px;
}

.training-form-box h4 {
  font-size: 18px !important;
  text-transform: uppercase;
  color: #333db7 !important;
}

.contact-form {
  padding: 16px 0px;
}

.contact-form .ant-form-item {
  margin-bottom: 10px;
}

.contact-form .ant-form-item-control-input-content {
  height: 38px;
}

.contact-form .ant-form-item-control-input-content input {
  height: 100%;
}

.contact-form .ant-select-selector,
.contact-form .ant-select-selection-search-input {
  height: 38px !important;
}

.training-form-button {
  margin-top: 12px;
  background-color: transparent;
  color: #333db7;
  border: 1px solid #333db7;
}

.training-form-button:hover {
  background-color: #333db7;
  color: white;
  border: none;
}

.course-content {
  background-color: #f5f8ff;
  padding: 60px 10px;
}

.course-content div {
  max-width: 1100px;
  margin: auto;
}

.course-content h5 {
  color: #212665;
  font-size: 32px !important;
  font-weight: 400 !important;
  text-align: left !important;
}

.course-content .ant-collapse-header {
  font-size: 17px !important;
  color: #7a79a4 !important;
  padding: 12px 16px !important;
}

.course-content ul li {
  font-size: 14px;
  margin: 4px 0px;
}

.learn-from-us {
  background-color: white;
  padding: 45px 10px;
}

.learn-from-us .container {
  max-width: 1200px;
  text-align: center;
}

.learn-from-us h1 {
  font-size: 32px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  margin-bottom: 35px;
}

.learn-block {
  margin: 15px 0px;
  padding: 5px;
}

.learn-block img {
  margin-bottom: 15px;
}

.learn-block h3 {
  font-size: 28px;
  margin-bottom: 15px;
}

.learn-block p {
  font-size: 15px;
}

.course-content ul {
  margin-bottom: 8px;
}

#python-banner {
  background-image: url("../../assets/images/training-python.jpg");
  background-color: #6e6d93;
}

#data-science-banner {
  background-image: url("../../assets/images/training-datascience.jpg");
  background-color: #3c1d4c;
}

#machine-learning-banner {
  background-image: url("../../assets/images/training-machinelearn.jpg");
  background-color: #265935;
}

#computer-vision-banner {
  background-image: url("../../assets/images/training-compvision.jpg");
  background-color: #167864;
}

#nlp-banner {
  background-image: url("../../assets/images/training-langproc.jpg");
  background-color: #627816;
}

#robotics-training-banner {
  background-image: url("../../assets/images/training-robotics.jpg");
  background-color: #243b88;
}

@media (min-width: 1200px) {
  .training-banner {
    padding: 15px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .training-info {
    padding: 50px 12px;
  }

  .training-info p {
    font-size: 16px;
  }

  .training-form-div {
    padding: 26px 85px;
  }

  .course-content {
    padding: 120px 10px;
  }

  .course-content .ant-collapse-header {
    font-size: 18px !important;
    color: #7a79a4 !important;
    padding: 16px 24px !important;
  }

  .course-content ul {
    margin-bottom: 12px;
  }

  .course-content ul li {
    font-size: 16px;
    margin: 6px 0px;
  }

  .learn-from-us {
    padding: 75px 10px;
  }

  .learn-block {
    padding: 5px 15px;
  }

  .learn-block p {
    font-size: 16px;
  }
}
