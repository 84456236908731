.products-banner {
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  min-height: 400px;
  margin-top: -105px;
}

#image-banner {
  background-image: url("../../assets/images/banners/image_banner_mobile.jpg");
}

#video-banner {
  background-image: url("../../assets/images/banners/video_banner_mobile.jpg");
}

#document-banner {
  background-image: url("../../assets/images/banners/document_banner_mobile.jpg");
}

#text-banner {
  background-image: url("../../assets/images/banners/text_banner_mobile.jpg");
}

.products-content {
  padding: 35px 5px;
  background-color: #f5f8ff;
}

.products-content .container-xl {
  max-width: 1200px;
  padding: 35px 10px;
}

.products-content h1 {
  text-align: center !important;
  width: 100%;
}

.products-content h1 img {
  margin-right: 8px;
  width: 55px;
  height: 55px;
}

.products-content p {
  text-align: center !important;
  width: 100%;
  margin-bottom: 35px;
}

.products-row {
  margin: 40px 0px;
}

.top-card-outer {
  padding: 12px;
}

.top-card-inner {
  background-color: white;
  padding: 28px 18px;
  height: 185px;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
}

.top-card-inner-big {
  height: 316px !important;
}

.top-card-inner h4 {
  text-align: left !important;
  font-weight: 500;
  line-height: 1.2;
  font-size: 22px;
}

.top-card-inner p {
  text-align: left !important;
  line-height: 28px;
  font-size: 14px;
}

.ant-tabs-nav-list {
  margin: auto;
}

.ant-tabs-tab {
  font-size: 14px;
}

.ant-tabs-tabpane {
  background-color: #fff;
  padding: 12px 0px;
}

.ant-tabs-nav {
  margin-bottom: 0px !important;
}

.product-sub-tab .code-box {
  padding: 5px;
}

.product-sub-tab h6 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
}

.product-sub-tab pre {
  margin-bottom: 0px !important;
  line-height: 1.8;
  box-shadow: 4px 4px 18px #f1f1f1;
  padding: 16px 8px;
}

code {
  color: #e83e8c !important;
}

.ant-tabs,
.ant-tabs-top,
.ant-tabs-mobile {
  width: 100%;
}

.code-run-button,
.code-run-button:focus,
.code-run-button:hover {
  float: right;
  margin-top: 12px;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  border: none;
}

.ant-popover-arrow {
  display: none !important;
}

.ant-popover-content {
  position: relative;
  margin: 40px 20px -15px 20px;
  height: 300px;
  width: 280px;
}

.ant-popover-content ::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.ant-popover-content ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.ant-popover-content ::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.ant-popover-content ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-popover-inner {
  height: 100%;
  background-color: white;
}

.ant-popover-inner-content {
  height: 100%;
  overflow: auto;
  font-size: 11px;
}

.code-content {
  width: 100%;
}

.code-content .close-button {
  color: red;
  font-size: 20px;
  margin-right: -10px;
}

.code-content h3 {
  font-size: 16px;
  font-weight: bold;
  color: #212665;
}

.code-content hr {
  margin: 5px -16px;
}

.product-sub-tab .image-box {
  margin-top: 5px;
  padding: 0px;
}

.product-sub-tab img,
.product-sub-tab video {
  width: 100%;
  height: auto;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.txtbf {
  display: inline-block;
  position: relative;
  color: black;
  animation-name: fadeIn;
  transition: 0.4s;
}

.txtbf:before {
  position: absolute;
  top: -11px;
  font-size: 10px;
}

.txtperson {
  color: white;
  background-color: #0066ff;
  padding: 0px;
}

.txtperson:before {
  content: "Person";
  line-height: 1;
  color: #0066ff;
}

.txtorganization {
  color: white;
  background-color: #e77806;
  padding: 0px;
}

.txtorganization:before {
  content: "Organization";
  line-height: 1;
  color: #e77806;
}

.txttime {
  color: white;
  background-color: green;
  padding: 0px;
}

.txttime:before {
  content: "Date";
  line-height: 1;
  color: green;
}

.txtnorp {
  color: white;
  background-color: #a900ff;
  padding: 0px;
}

.txtnorp:before {
  content: "NORP";
  line-height: 1;
  color: #a900ff;
}

.product-sub-tab pre {
  padding: 24px 16px;
  height: 300px;
  line-height: 2;
  font-size: 10px;
}

.product-sub-tab .image-box h6 {
  margin-top: 0px;
  padding: 0px 32px;
  font-size: 11px;
}

@media (min-width: 1200px) {
  .products-banner {
    min-height: 450px;
    margin-top: -90px;
  }

  #image-banner {
    background-image: url("../../assets/images/banners/image_banner.jpg");
  }

  #video-banner {
    background-image: url("../../assets/images/banners/video_banner.jpg");
  }

  #document-banner {
    background-image: url("../../assets/images/banners/document_banner.jpg");
  }

  #text-banner {
    background-image: url("../../assets/images/banners/text_banner.jpg");
  }

  .top-card-inner {
    height: 180px;
    border-radius: 6px;
  }

  .top-card-inner-big {
    height: 275px !important;
  }

  .top-card-inner h4 {
    line-height: 1.2;
    font-size: 21px;
  }

  .top-card-inner p {
    line-height: 26px;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .ant-tabs-tabpane {
    padding: 26px;
  }

  .product-sub-tab h6 {
    font-size: 14px;
  }

  .product-sub-tab pre {
    padding: 24px 16px;
    height: 300px;
    line-height: 2;
    font-size: 11px;
  }

  .product-sub-tab .image-box {
    margin-top: 0px;
    padding: 0px 32px;
  }

  .product-sub-tab .image-box h6 {
    margin-top: 0px;
    padding: 0px 32px;
    font-size: 13px;
  }

  .ant-popover-content {
    margin: 0px 300px -40px 0px;
    height: 400px;
    width: 350px;
  }

  .ant-popover-inner-content {
    font-size: 14px;
  }

  .ant-tabs-tab {
    font-size: 16px;
  }
}
