.slider-container {
  position: relative;
}

.wrapper {
  max-width: 1335px;
  box-sizing: border-box;
  padding: 0 20px;
  margin: auto;
}

.wrapper a {
  display: inline-block;
  margin: 5px;
}

.wrapper a:first-child {
  margin-left: 0;
}

.slider-wrapper {
  position: relative;
  height: 70vh;
  overflow: hidden;
}

.slide {
  height: 70vh;
  background-size: cover !important;
}

.slide::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
}

/* .previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: url("http://svgshare.com/i/41_.svg") no-repeat center center /
    16px;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  cursor: pointer;
}

.previousButton:hover,
.nextButton:hover {
  background: url("http://svgshare.com/i/41_.svg") no-repeat center center /
    16px;
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

/* .previousButton:hover {
  left: -10px;
} */

/* .nextButton {
  right: 0;
} */

/* .nextButton:hover {
  right: -10px;
} */

.slider-container ul {
  /* Center the content */
  align-items: center;
  display: flex;
  justify-content: center;

  /* Reset styles */
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.slider-container ul li {
  /* Rounded border */
  width: 10px;
  height: 10px;
  margin: 3px;
  /* Inactive dot */
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #bdbdd5;

  /* OPTIONAL: Spacing between dots */
}
.slider-container ul .active {
  /* Active dot */
  background-color: black;
}

.container-company-logo{
  margin: 16px;
  display: flex;
  flex-direction: column;
  padding: 4px;
}

.company-logo-title{
  width: 100%;
  text-align: center;
  color: #212665;
  font-weight: bolder;
}


.company-logo-main-div .slick-track{
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.company-logo-main-div{
  margin-top: 12px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}


.company-logo-image{
  width: 140px !important;
  object-fit: cover;
}

.company-logo-image-honda{
  width: 45% !important; 
 
}

@media screen and (max-width:369px) {
  .company-logo-image {
    width: 55px !important;
    padding: 19px 16px;
  }
}

@media screen and (max-width:512px) and (min-width:370px){
  
  .company-logo-image {
    width: 80px !important;
    padding: 19px 16px;
  }
}

@media screen and (max-width: 720px) and (min-width:513px){
  .company-logo-image {
    width: 115px !important; 
    padding: 20px 20px;
  }
}

@media screen and (max-width: 1000px) and (min-width:721px){
  .company-logo-image { 
    width: 120px !important;
    padding: 20px 14px;
  }

  .company-logo-image-honda{
    width: 65% !important;  
  }
}


@media (max-height: 500px) {
  .slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }

}

@media (max-width: 640px) {
  .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  }
}

@media (max-height: 600px) {
  /* .slider-content .inner h1 {
    font-size: 32px;
  } */
}

@media (max-width: 640px) {
  /* .slider-content .inner h1 {
    font-size: 32px;
  } */
}
