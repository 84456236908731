.solutions-banner {
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  min-height: 400px;
  margin-top: -105px;
}

#healthcare-banner {
  background-image: url("../../assets/images/banners/healthcare_banner_mobile.png");
}

#retail-banner {
  background-image: url("../../assets/images/banners/retail_banner_mobile.png");
}

#mobility-banner {
  background-image: url("../../assets/images/banners/mobility_banner_mobile.png");
}

#drone-banner {
  background-image: url("../../assets/images/banners/drone_banner_mobile.png");
}

#face-banner {
  background-image: url("../../assets/images/banners/face_banner_mobile.png");
}

#robotics-banner {
  background-image: url("../../assets/images/banners/robotics_banner_mobile.png");
}

.solutions-content {
  padding: 35px 5px;
}

.solutions-content .container-xl {
  max-width: 1200px;
  padding: 35px 10px;
}

.solutions-content h1 {
  text-align: center !important;
  width: 100%;
}

.solutions-content p {
  text-align: center !important;
  width: 100%;
  margin-bottom: 35px;
}

.topcard {
  padding: 18px;
}

.topcard img {
  height: 100%;
  width: 100%;
}

.topcard .image-box {
  position: relative;
}

.topcard .image-box .after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  color: #fff;
  padding: 26px;
}

.topcard .image-box:hover .after {
  display: block;
  transition: 1s;
  background: rgba(0, 0, 0, 0.6);
}

.topcard .top-inner-box {
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-transform: translateZ(30px);
  transform: translateZ(30px);
  border: 10px solid #3f46b6;
  transition: border 0.4s ease;
}

.top-inner-box h3 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 0px;
}

.content-card {
  margin: 20px auto 50px auto;
}

.content-card .content-card-info {
  padding: 15px;
}

.solutions-row {
  margin: 40px 0px;
}

.content-card .content-card-info h4 {
  color: #3f46b6;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 6px;
}

.content-card .content-card-info p {
  font-size: 12px;
  margin-bottom: 0px;
  line-height: 1.6;
  text-align: left !important;
  color : #212529;
}

.content-card .content-card-info div {
  margin: 15px 0px;
  text-align: left;
}

.content-card .content-card-image {
  height: 220px;
}

.content-card .content-card-image img {
  height: 100%;
  width: 100%;
}

/*Robotics*/
.robotics-card {
  background-color: white;
  margin: 24px 0px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0px;
}

.robotics-card .gif-box {
  height: 210px;
}

.robotics-card .gif-box img {
  width: 100%;
  height: 100%;
}

.robotics-card .card-info {
  height: auto;
  padding: 24px;
}

.robotics-card .card-info h5 {
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 18px;
  font-size: 22px;
}

.robotics-card .card-info p {
  line-height: 1.5;
  text-align: left !important;
  font-size: 16px;
  font-weight: 400;
}

@media (min-width: 1200px) {
  .solutions-banner {
    min-height: 450px;
    margin-top: -90px;
  }

  #healthcare-banner {
    background-image: url("../../assets/images/banners/healthcare_banner.png");
  }

  #retail-banner {
    background-image: url("../../assets/images/banners/retail_banner.png");
  }

  #mobility-banner {
    background-image: url("../../assets/images/banners/mobility_banner.png");
  }

  #drone-banner {
    background-image: url("../../assets/images/banners/drone_banner.png");
  }

  #face-banner {
    background-image: url("../../assets/images/banners/face_banner.png");
  }

  #robotics-banner {
    background-image: url("../../assets/images/banners/robotics_banner.png");
  }

  .solutions-row {
    margin: 30px 0px 90px 0px;
  }

  .content-card .content-card-info h4 {
    margin-bottom: 16px;
    text-align: left !important;
    padding: 0px 28px;
  }

  .content-card .content-card-image {
    height: 320px;
  }

  .content-card .content-card-info h4 {
    color: #3f46b6;
    font-weight: 500;
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 6px;
  }

  .content-card .content-card-info p {
    font-size: 14px;
    line-height: 1.8;
    padding: 0px 28px;
    text-align: left !important;
    color : #212529;
  }

  .content-card .content-card-info {
    padding: 35px;
  }

  .content-card .content-card-image,
  .content-card .content-card-info div {
    padding: 15px 45px;
  }

  .robotics-card .content-card-info {
    height: 295px !important;
  }

  .robotics-card .card-info {
    height: 275px;
  }
}

.project {
  width: 33%;
  display: inline-block;
  text-align: center;
  padding: 15px;
}
.project-list:after {
  content: " ";
  display: block;
  clear: both;
}
.project__image {
  display: block;
  position: relative;
}
.project__image img {
  width: 100%;
  max-width: 100%;
  max-height: auto;
  display: block;
}
.project__image:after {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4));
  transition: opacity 0.3s ease;
  opacity: 0;
}
.project__card {
  position: relative;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 10px 30px transparent;
}
.project__card.hover-in {
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}
.project__card.hover-out {
  transition: -webkit-transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
  transition: transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
}
.project:hover .project__card {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
}
.project:hover .project__image:after {
  opacity: 1;
}
.project:hover .project__detail {
  border-width: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
}
.project:hover .project__title,
.project:hover .project__category {
  -webkit-transform: translateY(0) scale(1);
  -ms-transform: translateY(0) scale(1);
  transform: translateY(0) scale(1);
  opacity: 1;
}
.project:hover .project__title {
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1;
}
.inilab {
  font-size: 15px;
  transition: all 0.5s;
}
.project:hover .inilab {
  display: none;
}
.project__detail {
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-transform: translateZ(30px);
  transform: translateZ(30px);
  border: 0 solid #3f46b6;
  transition: border 0.4s ease;
}
.project__title {
  margin: 0 0 10px;
  font-size: 20px;
  font-weight: 400;
  transition: 0.4s ease;
  opacity: 0;
  -webkit-transform: translateY(40px) scale(0);
  -ms-transform: translateY(40px) scale(0);
  transform: translateY(40px) scale(0);
  will-change: transform;
  color: white;
}
