body {
  color: #212529;
}

.ps-timeline-sec {
  position: relative;
}
.ps-timeline-sec .container {
  position: relative;
}

.ps-timeline-sec .container ol:before {
  background: #d0d2ed;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 100%;
  position: absolute;
  left: 8px;
  top: -185%;
}
.ps-timeline-sec .container ol:after {
  background: #d0d2ed;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 100%;
  position: absolute;
  right: 8px;
  top: -185%;
}
.ps-timeline-sec .container ol.ps-timeline {
  margin: 140px 0;
  padding: 0;
  border-top: 2px solid #d0d2ed;
  list-style: none;
}
.ps-timeline-sec .container ol.ps-timeline li {
  float: left;
  width: 16.66%;
  padding-top: 30px;
  position: relative;
}
.ps-timeline-sec .container ol.ps-timeline li span {
  width: 30px;
  height: 30px;
  margin-left: -15px;
  background: #fff;
  border: 4px solid #d0d2ed;
  border-radius: 50%;
  box-shadow: 0 0 0 0 #fff;
  text-align: center;
  line-height: 22px;
  color: #d0d2ed;
  font-size: 0.9em;
  font-style: normal;
  position: absolute;
  top: -15px;
  left: 50%;
}
.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before {
  content: "";
  color: #d0d2ed;
  width: 2px;
  height: 30px;
  background: #d0d2ed;
  position: absolute;
  top: -30px;
  left: 46%;
}
.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after {
  content: "";
  color: #d0d2ed;
  width: 8px;
  height: 8px;
  background: #d0d2ed;
  position: absolute;
  bottom: 52px;
  left: 33%;
  border-radius: 50%;
}
.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
  content: "";
  color: #d0d2ed;
  width: 2px;
  height: 50px;
  background: #d0d2ed;
  position: absolute;
  bottom: -50px;
  left: 50%;
}
.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:after {
  content: "";
  color: #d0d2ed;
  width: 8px;
  height: 8px;
  background: #d0d2ed;
  position: absolute;
  top: 90px;
  left: 44%;
  border-radius: 100%;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
  position: absolute;
  bottom: 0;
  margin-bottom: 95px;
  width: 100%;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
  display: table;
  margin: 0 auto;
  width: 35px;
  height: 35px;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
  position: absolute;
  margin-top: 60px;
  width: 100%;
}
.ps-timeline-sec .container ol.ps-timeline li .img-handler-bot img {
  display: table;
  margin: 0 auto;
}
.ps-timeline-sec .container ol.ps-timeline li p {
  text-align: center;
  width: 60%;
  margin: 0 auto;
  color: #444bbd;
  font-size: 9px;
}
.ps-timeline-sec .container ol.ps-timeline li .ps-top {
  position: absolute;
  bottom: 0;
  margin-bottom: 100px;
}
.ps-timeline-sec .container ol.ps-timeline li .ps-bot {
  position: absolute;
  margin-top: -4px;
  left: 50%;
  margin-left: -65%;
  width: 70%;
}

.ps-timeline-sec p {
  font-size: 13px;
}

.timeline-label {
  transform: rotate(-90deg);
  margin-left: 22px !important;
}

@media (min-width: 768px) {
  .ps-timeline-sec .container ol:before {
    background: #d0d2ed;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    left: 8px;
    top: -195%;
  }
  .ps-timeline-sec .container ol:after {
    background: #d0d2ed;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    right: 8px;
    top: -195%;
  }
  .ps-timeline-sec .container ol.ps-timeline {
    margin: 225px 0;
    padding: 0;
    border-top: 2px solid #d0d2ed;
    list-style: none;
  }
  .ps-timeline-sec .container ol.ps-timeline li {
    float: left;
    width: 16.66%;
    padding-top: 30px;
    position: relative;
  }
  .ps-timeline-sec .container ol.ps-timeline li span {
    width: 50px;
    height: 50px;
    margin-left: -25px;
    background: #fff;
    border: 4px solid #d0d2ed;
    border-radius: 50%;
    box-shadow: 0 0 0 0 #fff;
    text-align: center;
    line-height: 45px;
    color: #d0d2ed;
    font-size: 2em;
    font-style: normal;
    position: absolute;
    top: -26px;
    left: 50%;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before {
    content: "";
    color: #d0d2ed;
    width: 2px;
    height: 50px;
    background: #d0d2ed;
    position: absolute;
    top: -50px;
    left: 50%;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after {
    content: "";
    color: #d0d2ed;
    width: 8px;
    height: 8px;
    background: #d0d2ed;
    position: absolute;
    bottom: 90px;
    left: 43%;
    border-radius: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
    content: "";
    color: #d0d2ed;
    width: 2px;
    height: 50px;
    background: #d0d2ed;
    position: absolute;
    bottom: -50px;
    left: 50%;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:after {
    content: "";
    color: #d0d2ed;
    width: 8px;
    height: 8px;
    background: #d0d2ed;
    position: absolute;
    top: 90px;
    left: 44%;
    border-radius: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
    position: absolute;
    bottom: 0;
    margin-bottom: 115px;
    width: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
    display: table;
    margin: 0 auto;
    width: 60px;
    height: 60px;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
    position: absolute;
    margin-top: 60px;
    width: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot img {
    display: table;
    margin: 0 auto;
  }
  .ps-timeline-sec .container ol.ps-timeline li p {
    text-align: center;
    width: 80%;
    margin: 0 auto;
    color: #444bbd;
    font-size: 14px;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-top {
    position: absolute;
    bottom: 0;
    margin-bottom: 100px;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-bot {
    position: absolute;
    margin-top: 10px;
    left: 50%;
    margin-left: -42%;
    width: 84%;
  }

  .ps-timeline-sec p {
    font-size: 13px;
  }

  .timeline-label {
    transform: rotate(0deg);
    margin-left: 0px !important;
  }
}

.slick-prev:before,
.slick-next:before {
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 30px !important;
  margin-top: -10px;
}

.slick-dots-bottom{
  margin-bottom: 15px !important;
}

/*Map*/

.maparea {
  padding: 40px;
  box-sizing: border-box;
  background-image: url("../src/assets/images/map.png");
  width: 100%;
  background-size: 100%;
  min-height: 617px;
  background-position: center;
  background-repeat: no-repeat;
}

.client-logo {
  box-shadow: 2px 2px 10px #ccc;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  margin: 15px;
  display: inline-block;
  background-color: white;
}
.client-logo img {
  max-width: 100%;
}
.almidd {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  font-size: 11px;
}
.txtbf:before {
  position: absolute;
  top: -10px;
  font-size: 10px;
}
.lnht {
  line-height: 30px;
}
.txtbf {
  color: white;
  display: inline-block;
  position: relative;
}
.txtperson {
  background-color: #fff;
  color: black;
  animation: txtper 0s 1s forwards;
}
.delayshow {
  animation: fadein 5s;
  -moz-animation: fadein 3s;
  -webkit-animation: fadein 3s;
  -o-animation: fadein 3s;
}
.location-box {
  border-radius: 8px;
  border: 1px solid paleturquoise;
  font-size: 1.8vh;
  position: absolute;
  background-color: white;
  padding: 10px;
  min-width: 80px;
  transform: translateX(-50%);
  z-index: 50;
  line-height: 1.2;
}
.maparea img {
  z-index: -1;
}
.location-box h5 {
  font-size: 1.5vh;
}
.arrow {
  min-width: 0px;
  border: 1px solid skyblue;
  position: absolute;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes txtper {
  to {
    background-color: #0066ff;
    color: white;
  }
}
.txtperson:before {
  content: "Person";
  animation: txtperbf 0s 1s forwards;
  visibility: hidden;
  line-height: 1;
  color: #0066ff;
}
@keyframes txtperbf {
  to {
    visibility: visible;
  }
}
.txtdate {
  background-color: #fff;
  color: black;
  animation: txtdat 0s 2s forwards;
}
@keyframes txtdat {
  to {
    background-color: #34c245;
    color: white;
  }
}
.txtdate:before {
  content: "Date";
  animation: txtdatbf 0s 2s forwards;
  visibility: hidden;
  line-height: 1;
  color: #34c245;
}
@keyframes txtdatbf {
  to {
    visibility: visible;
  }
}
.txtorg {
  background-color: #fff;
  color: black;
  animation: txtor 0s 3s forwards;
}
@keyframes txtor {
  to {
    background-color: #e77806;
    color: white;
  }
}
.txtorg:before {
  content: "Organisation";
  animation: txtorbf 0s 3s forwards;
  visibility: hidden;
  line-height: 1;
  color: #e77806;
}
@keyframes txtorbf {
  to {
    visibility: visible;
  }
}
.txtnorp {
  background-color: #fff;
  color: black;
  animation: txtnor 0s 4s forwards;
}
@keyframes txtnor {
  to {
    background-color: #a900ff;
    color: white;
  }
}
.txtnorp:before {
  content: "NORP";
  animation: txtnorbf 0s 4s forwards;
  visibility: hidden;
  line-height: 1;
  color: #a900ff;
}
@keyframes txtnorbf {
  to {
    visibility: visible;
  }
}
.textwrap {
  line-height: 34px;
}
.segbg {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 80%;
  left: 0px;
  background-color: #eedc23;
  opacity: 0.8;
  z-index: 2;
}
.segcar {
  position: absolute;
  top: 44%;
  width: 51%;
  left: 15%;
  opacity: 0.8;
  z-index: 3;
}
.segrd {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 110px;
  left: 0px;
  background-color: #77e949;
  opacity: 0.8;
  z-index: 1;
}
