@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap");

body {
  background-color: #ffffff;
  font-family: "Montserrat", sans-serif;
}

.ant-carousel .slick-dots {
  margin-left: 0px;
}

h1 {
  font-size: 24px;
  color: #212665;
  font-weight: bolder;
}

h2 {
  font-size: 20px;
  color: #212665;
  font-weight: bolder;
}

.ant-message-notice {
  margin-top: 60px;
}

/*Navbar*/

.ant-dropdown {
  min-width: 345px !important;
}

.ant-dropdown-menu-item {
  padding: 8px 22px;
  border-bottom: 1px solid #f1f1f1;
}

.ant-dropdown-menu-item a {
  font-size: 13px;
}

#nav-bar-init {
  transition: 0.5s;
  background-color: transparent;
  position: absolute;
}

.navbar {
  padding: 0px;
}

.navbar .container-xl {
  max-width: 1150px !important;
  margin: 0 auto;
}

#nav-bar {
  transition: 0.3s;
  background-color: white;
  /* box-shadow: 0 8px 6px -6px black; */
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

#navbar_logo {
  transition: 0.4s;
  margin-left: 12px;
}

.logo-expanded {
  height: 115px;
  position: absolute;
  clip: rect(0px, 100px, 200px, 0px);
}

.logo-contracted {
  height: 55px;
  position: absolute;
  clip: rect(0px, 250px, 42px, 0px);
}

#navbarSupportedContent ul {
  background-color: white;
  padding: 12px;
  margin-top: 3px;
}

.navbar-toggler:focus {
  outline: none;
}

.secondary-drop {
  border: none;
}

.nav-item {
  padding: 8px 3px;
  font-size: 13px;
}

.nav-item a {
  color: #222d65;
  line-height: 26px;
}

.secondary-drop {
  padding: 4px !important;
  margin: 0px !important;
  font-size: 14px;
}

.secondary-drop li {
  padding-top: 12px !important;
}

/*Common*/
.page {
  margin-top: 164px;
}

.page-home {
  margin-top: 0px;
}

/*Home*/

.home-banner {
  background-image: url("../src/assets/images/banner-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 565px;
  background-position: top right;
  padding: 60px 30px 85px 30px;
  margin: auto;
  background-color: #f5f8ff;
}

.home-banner section {
  max-width: 1700px;
}

.home-banner .home-banner-text {
  padding: 0px;
  margin-top: 60px;
}

.carousel-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-slide h1 {
  text-align: left;
}

.carousel-slide p {
  font-size: 14px;
  padding-right: 60px;
  margin-bottom: 35px;
}

.banner-button {
  background-color: transparent;
  color: #212665;
  border: 1px solid #212665;
  width: 110px;
  height: 36px;
  padding: 5px;
  text-align: center;
  transition: 1000ms;
}

.banner-button:hover {
  background-color: #212665 !important;
  color: white !important;
}

.home-banner-video {
  display: none;
}

.ant-tabs-tab-btn {
  font-size: 13px;
}

.ant-tabs-tab {
  height: 50px;
  padding-bottom: 0px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: black !important;
}

.ant-tabs-ink-bar {
  height: 3px !important;
  background: #0f9b5e !important;
}

.ant-tabs-tab:hover {
  height: 50px;
  padding-top: 3px !important;
  border-bottom: 3px #0f9b5e solid;
  color: black;
}

.ant-carousel .slick-dots li button {
  background: #000000;
  opacity: 0.4;
  height: 9px;
  width: 9px;
  margin-top: 6px;
  margin-left: 4px;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 0.8;
  background: #000000;
}

/*Contact Form*/
#contact-banner {
  background-color: #f5f8ff;
  height: auto;
  padding: 60px 30px;
}

#contact-banner .container-xl {
  max-width: 1150px;
}

#contact-banner h1 {
  text-align: center;
  margin-bottom: 35px;
}

.contact-box {
  padding: 15px;
}

.contact-card {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.contact-card h5 {
  text-align: center;
  font-size: 14px;
  font-weight: 800;
  color: black;
}

.contact-card-icon {
  font-size: 48px;
  display: block;
  text-align: center;
  margin-bottom: 15px;
}

.contact-card-list {
  padding: 0px;
  list-style: none;
}

.contact-card-list li {
  text-align: center;
  font-size: 13px;
}

.contact-card-list li a {
  color: rgba(0, 0, 0, 0.7);
}

.contact-card-list li a:hover {
  color: blue;
}

.social-media-list {
  margin-top: 36px;
  text-align: center;
}

.social-media-icons {
  padding: 0px;
  list-style: none;
}

.social-media-icons li {
  display: inline;
  margin: 8px;
}

.social-media-icons li img {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

#contact-form {
  padding: 35px 20px;
}

.form-box {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border: 1px solid #dee2e6;
  padding: 50px 12px;
  background-color: #fff;
}

.form-box .form-group {
  width: 100%;
  margin-bottom: 1rem;
}

.form-box .form-control {
  height: 34px !important;
  font-size: 13px !important;
}

.form-box .form-text {
  height: 60px !important;
}

#contact-form h1 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 10px !important;
}

#contact-form p {
  text-align: center;
  font-size: 13px;
  margin-bottom: 25px;
}

#contact-form .form {
  padding: 12px 25px;
}

.form-box .form-check {
  margin-left: 16px;
}

.contact-button {
  margin-left: 16px;
  margin-top: -6px;
  color: #343eba;
  border-color: #343eba;
  background-color: #fff;
}

/*Privacy Policy*/

#privacy-policy {
  padding: 35px 15px;
  background-color: #f5f8ff;
}

#privacy-policy .container-xl {
  padding: 35px 15px;
  background-color: #fff;
  max-width: 1350px;
}

#privacy-policy h1 {
  text-align: center;
  margin-bottom: 25px;
}

#privacy-policy p {
  margin-bottom: 20px;
  font-size: 14px;
  color: #212529;
}

#privacy-policy h6 {
  margin-bottom: 20px;
  font-size: 15px;
  color: #212529;
  font-weight: 800;
  margin-bottom: 12px;
}

.privacy-page-list li {
  margin-bottom: 8px;
  font-size: 14px;
  color: #212529;
}

.privacy-inner-list li {
  margin-bottom: 6px;
  font-size: 14px;
  color: #212529;
}

/*Career*/
#career-banner {
  background-image: url("./assets/images/careers_mobile.png");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  min-height: 400px;
  margin-top: -105px;
}

#career-content .container {
  padding: 65px 15px;
  max-width: 1000p;
}

#career-content h1 {
  text-align: center;
}

#career-content p {
  text-align: center;
  margin-bottom: 18px;
  font-size: 14px;
  color: #212529;
}

.career-accordion {
  margin-top: 30px;
}

.career-accordion .ant-collapse-header {
  background-color: rgba(0, 0, 0, 0.03);
  height: 73px;
  width: 100%;
  color: #7a79a4 !important;
  font-size: 18px;
  padding: 25px 40px;
  font-weight: 400;
}

.career-panel {
  padding: 12px;
}

.career-panel h6 {
  font-size: 15px;
  font-weight: 900;
  color: #212529;
}

.career-panel ul {
  margin-bottom: 40px;
}

.career-panel ul li {
  margin-bottom: 10px;
  color: #212529;
  font-size: 14px;
  line-height: 1.3;
}

/*footer*/
footer {
  background-color: #f5f8ff;
  padding: 40px 12px;
}

footer .container {
  max-width: 1150px;
}

footer h6 {
  font-size: 14px;
  color: #212665;
  margin-bottom: 6px;
  text-transform: uppercase;
}

.footer-list {
  list-style: none;
  padding-left: 0px;
  font-size: 12px;
  margin-bottom: 20px;
}

.footer-list li {
  padding: 3px;
}

.footer-list li a {
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.footer-list li a:hover {
  color: blue;
}

.footer-tag {
  text-align: center;
  font-size: 12px;
  margin: 8px 0px;
}

/*About Page*/

#about-banner {
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  min-height: 400px;
  margin-top: -80px;
  background-image: url("./assets/images/aboutusbanner.gif");
}

#about-top-section {
  background-color: #f5f8ff;
  padding: 25px 10px;
}

#about-top-section .container {
  max-width: 800px;
  padding: 25px 15px;
  margin-bottom: 20px;
}

#about-top-section h1 {
  text-align: center;
  margin-bottom: 30px;
}

#about-top-section p {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
}

.director-card-outer {
  padding: 15px;
  text-align: center;
  margin-bottom: 15px;
}

.director-card-outer img {
  width: auto;
  height: auto;
  margin-bottom: 10px;
}

.director-card-outer h4 {
  font-size: 18px;
  margin-bottom: 6px;
  color: #222aac;
}

#about-bottom-section {
  background-color: #fff;
  padding: 35px 10px;
}

#about-bottom-section h1 {
  text-align: center;
  margin-bottom: 8px;
}

#about-bottom-section p {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 12px;
  text-align: center;
}

#about-bottom-section .container {
  max-width: 250px;
  padding: 15px;
}

.team-member {
  padding: 8px;
  text-align: center;
  margin-bottom: 4px;
}

.team-member img {
  width: auto;
  height: auto;
  margin: auto;
  border: 1px solid #e1e1e1;
  cursor: pointer;
}

.team-member img:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#home-tools {
  padding: 40px 20px;
  background-color: #f5f8ff;
}

#home-tools h1 {
  text-align: center;
  margin-bottom: 15px;
}

#home-tools p {
  font-size: 13px;
  text-align: center;
}

#home-tools .tools-row {
  max-width: 800px;
  padding: 3px 8px;
}

#home-tools .tools-box {
  padding: 6px;
  text-align: center;
}

#home-tools .tools-box img {
  margin: auto;
  width: auto;
  height: auto;
  max-height: 50px;
}

.slick-slide,
.slick-active {
  text-align: center;
}

.slick-arrow {
  margin-top: -60px;
}

.slick-dots .slick-active {
  width: 16px !important;
}

#testimonials {
  background-color: white;
  padding: 30px 10px 0px 10px;
}

#testimonials h1 {
  text-align: center;
}

#testimonials p {
  text-align: center;
  font-size: 13px;
}

#testimonials .container {
  max-width: 750px;
  margin: auto;
  padding: 20px 30px;
}

#testimonials h3 {
  text-align: center;
}

#testimonials p {
  font-size: 13px !important;
}

.testimonial-card {
  padding: 25px;
}

.testimonial-card .testimonial-card-inner {
  height: 435px;
}

.testimonial-card .testimonial-card-inner div {
  text-align: center;
  height: 280px;
  padding: 28px 16px;
  border: 1px solid #e1e1e1;
}

.testimonial-card .testimonial-card-inner div img {
  margin: auto;
  width: auto;
  max-width: 100%;
  height: auto;
  margin-bottom: 16px;
}

.client-image {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: -30px auto 5px auto;
}

.testimonial-card .testimonial-card-inner h5 {
  font-size: 13px;
  font-weight: 700;
  text-align: center;
}

.testimonial-card .testimonial-card-inner h6 {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

#map-section {
  background-color: #f5f8ff;
  padding: 10px 15px;
}

#map-section p {
  font-size: 15px;
  margin-bottom: 50px;
}

#home-products {
  background-color: #f5f8ff;
  padding: 60px 10px;
}

#home-products .container-xl {
  max-width: 1140px;
}

.product-tab-list {
  padding: 35px 0px 5px 0px;
  background-color: white;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  height: 215px;
}

.product-tab-list h1 {
  text-align: center;
}

.product-tab-list p {
  text-align: center;
  font-size: 12px;
  margin-bottom: 30px;
}

.home-product-list-icon {
  text-align: center;
  padding: 0px;
}

.home-product-list-icon img {
  cursor: pointer;
  display: block;
  margin: auto;
  height: 40px;
  width: 40px;
}

.home-product-list-icon span {
  font-size: 10px;
  display: block;
  height: 30px;
}

.mob-hide {
  display: none;
}

.mob-show {
  display: block;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #fff;
  margin: 2px auto auto auto;
}

.radio-list {
  margin-left: 12px;
}

.space-top {
  margin-top: 60px;
}

.space-top-desktop {
  margin-top: 0px;
}

.tools-style {
  background-color: #f8f9fa;
  padding: 25px 140px;
}

.ant-tabs-nav-list .ant-tabs-tab,
.ant-tabs-nav-list .ant-tabs-tab-active {
  margin: 0px 0px 0px -1px;
  padding: 0px 16px;
}

.ant-tabs-nav-operations {
  display: none !important;
}

@media (min-width: 375px) {
  #home-tools .tools-box img {
    margin: auto;
    width: auto;
    height: auto;
    max-height: 60px;
  }
}

@media (min-width: 425px) {
  #home-tools .tools-box img {
    margin: auto;
    width: auto;
    height: auto;
    max-height: 70px;
  }
}

@media (min-width: 1200px) {
  .mob-hide {
    display: block;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }

  .page-home {
    margin-top: 60px;
  }

  #home-tools .tools-box {
    padding: 6px;
    text-align: center;
  }

  .tools-tabs {
    max-width: 1000px;
  }

  #home-tools .tools-box img {
    margin: auto;
    width: auto;
    height: auto;
    max-height: 84px;
  }

  .home-banner .home-banner-text {
    margin-top: 0px;
  }

  .space-top {
    margin-top: 0px;
  }

  .space-top-desktop {
    margin-top: 60px;
  }

  .antd-carousel {
    margin-top: -82px !important;
  }

  .mob-show {
    display: none;
  }

  .ant-dropdown {
    min-width: 150px !important;
  }

  .ant-dropdown-menu-item {
    padding: 8px 22px;
    border-bottom: 1px solid #f1f1f1;
  }

  .home-banner {
    background-position: center center;
  }

  .ant-dropdown-menu-item:hover {
    background-color: rgba(18, 53, 117, 0.2);
  }

  #navbarSupportedContent ul {
    background-color: transparent;
    padding: 6px;
    margin-top: 0px;
  }

  .navbar-nav {
    background-color: transparent !important;
  }

  #navbar_logo {
    position: absolute;
    margin-top: -25px;
  }

  .tools-style {
    padding: 25px 0px;
  }

  .nav-item {
    padding: 8px 12px;
    margin: 0px 8px;
  }

  .secondary-drop {
    background-color: rgba(255, 255, 255, 0.85) !important;
    padding: 12px 22px !important;
    border: 1px solid #eee;
    width: 250px;
    position: absolute;
  }

  .secondary-drop li {
    border-bottom: 1px solid #eee;
    padding-bottom: 6px;
  }

  .navbar .container-xl {
    max-width: 1500px;
  }

  #testimonials {
    background-color: white;
    padding: 30px 10px 30px 10px;
  }

  /*Common*/
  .page {
    margin-top: 82px;
    padding-top: 70px;
  }

  /*Home*/
  .home-banner {
    height: 570px;
  }

  .carousel-slide {
    padding: 150px 120px 80px 115px;
  }

  .home-banner-video {
    display: unset;
    padding: 0px 0 0 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home-banner-video-img {
    width: 90%;
    height: auto;
  }

  .carousel-slide h1 {
    font-size: 36px;
    color: #212665;
    font-weight: bolder;
    text-align: left;
  }

  .carousel-slide p {
    font-size: 12px;
    padding-right: 60px;
    margin-bottom: 35px;
  }

  .carousel-slide button {
    font-size: 14px;
  }

  /*contact page*/
  .contact-box {
    padding: 30px;
  }

  .contact-card {
    background-color: white;
    border-radius: 8px;
    padding: 25px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    height: 250px;
  }

  .contact-card h5 {
    text-align: center;
    font-size: 17px;
    font-weight: 800;
    color: black;
  }

  .contact-card-icon {
    font-size: 54px;
    margin-bottom: 20px;
  }

  .contact-card-list li {
    font-size: 15px;
  }

  .social-media-list p {
    font-size: 17px;
    margin-bottom: 25px;
  }

  .social-media-icons li {
    margin: 12px;
  }

  .social-media-icons li img {
    height: 28px;
    width: 28px;
  }

  /*Contact*/
  #contact-form .form {
    padding: 25px 120px;
  }

  .form-box {
    max-width: 885px;
    padding: 42px 20px 60px 20px;
  }

  /* .form-box {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border: 1px solid #dee2e6;
    padding: 100px 12px;
    background-color: #fff;
  } */

  .form-box .form-group {
    width: 100%;
    margin-bottom: 2rem;
  }

  /*Privacy Policy*/
  #privacy-policy .container-xl {
    padding: 65px 120px;
  }

  #privacy-policy p {
    margin-bottom: 20px;
    font-size: 16px;
  }

  #privacy-policy h6 {
    margin-bottom: 8px;
    font-size: 17px;
    margin-top: 20px;
    text-transform: uppercase;
  }

  .privacy-page-list li {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .privacy-inner-list li {
    margin-bottom: 8px;
    font-size: 16px;
  }

  /*Careers*/
  #career-banner {
    background-image: url("./assets/images/careers.jpg");
    margin-top: -90px;
    min-height: 450px;
  }

  .career-accordion .ant-collapse-header {
    height: 65px;
  }

  /*footer*/
  footer {
    padding: 35px 15px 15px 15px;
  }

  footer h6 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .footer-list {
    font-size: 12px;
    padding: 0px;
  }

  .footer-list li {
    padding: 4px 4px 4px 0px;
    margin-bottom: 7px;
  }

  #about-bottom-section .container {
    max-width: 550px;
  }

  #about-banner {
    margin-top: -65px;
    min-height: 450px;
  }

  #home-tools p {
    font-size: 13x;
  }

  .client-image {
    margin: -45px auto 5px auto;
  }

  .product-tab-list {
    height: 230px;
  }

  .home-product-list-icon img {
    height: 60px;
    width: 64px;
  }

  .arrow-down {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #fff;
    margin: 2px auto auto auto;
  }

  .home-product-list-icon span {
    font-size: 12px;
  }

  .slideIn {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
  }

  .animate {
    animation-duration: 0.8s;
    -webkit-animation-duration: 0.8s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }

  #home-banner-1 {
    /* marginRight: "-100px",
            width: "810px",
            height: "550px",
            marginTop: "-40px",
            marginLeft: "-90px", */

    margin-top: -40px;
    height: 550px;
    width: auto;
    margin-left: 30px;
  }
}

@media (min-width: 1600px) {
  #home-banner-1 {
    margin-left: 100px;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}
